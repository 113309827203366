import { frontendURL } from 'dashboard/helper/URLHelper';
import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';

const Panel = () => import('./Panel.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/crm/panel'),
    name: 'crm_panel',
    component: Panel,
    meta: {
      permissions: [...ROLES, ...CONVERSATION_PERMISSIONS],
    },
  },
];
