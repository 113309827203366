<script>
import SupportIcon from '../../../assets/icons/SupportIcon.vue';
import { popoutChatWindow } from '../../../../widget/helpers/popoutHelper';
import Cookies from 'js-cookie';
export default {
  components: {
    SupportIcon,
  },
  methods: {
    openChat() {
      const cwCookie = Cookies.get('cw_conversation');
      const {
        location: { origin },
      } = window;
      popoutChatWindow(
        origin,
        'bPuLnxkKRRt6dDYphR7ke2kN',
        this.$root.$i18n.locale,
        cwCookie
      );
    },
  },
};
</script>

<template>
  <div>
    <button
      class="text-gray-700 w-10 h-10 my-2 flex items-center justify-center rounded-lg dark:hover:bg-slate-700 hover:bg-purple-200 relative"
      @click="openChat"
    >
      <SupportIcon />
    </button>
  </div>
</template>
